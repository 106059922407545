import { Link } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"
import { NoSsr } from "../components/NoSsr"
import { useSession } from "../hooks/useSession"
import Layout from "../components/layout/Layout"
import { ClipboardCheckIcon, ClipboardListIcon } from "@heroicons/react/solid"

const CTAButton = () => {
  const { isAuthenticated } = useSession()

  if (isAuthenticated)
    return (
      <div className="mt-5 max-w-md mx-auto flex flex-col justify-center md:mt-8">
        <div className="rounded-md shadow mt-10">
          <Link
            to="/atk/"
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-semibold rounded-md text-white bg-orange-600 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
          >
            <ClipboardListIcon className="w-6 h-6" />
            ผลตรวจ ATK
          </Link>
        </div>
        <div className="rounded-md shadow mt-10">
          <Link
            to="/vaccine/"
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-semibold rounded-md text-white bg-orange-600 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
          >
            <ClipboardCheckIcon className="w-6 h-6" />
            ประวัติการรับวัคซีน
          </Link>
        </div>
      </div>
    )

  return (
    <div className="mt-5 max-w-md mx-auto sm:flex sm:justify-center md:mt-8">
      <div className="rounded-md shadow">
        <Link
          to="/login/"
          className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-semibold rounded-md text-white bg-orange-600 hover:bg-orange-700 md:py-4 md:text-lg md:px-10"
        >
          เข้าสู่ระบบ (Login)
        </Link>
      </div>
    </div>
  )
}

const Index = () => (
  <Layout>
    <Helmet>
      <title>GoFight | KMITL</title>
    </Helmet>
    <div>
      <div className="text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
          <span className="block xl:inline text-medium text-orange-700">
            KMITL{" "}
          </span>
          <span className="block text-emerald-600 xl:inline">GoFight</span>
        </h1>
      </div>
      <div className="mt-6 md:mt-10 text-center">
        <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-4xl md:text-5xl">
          <span className="block mt-4">Scan QR Code</span>
          <span className="block mt-4 xl:inline"> ก่อนเข้าตึก/อาคาร </span>
        </h1>
        <NoSsr>
          <CTAButton />
        </NoSsr>
      </div>
    </div>
  </Layout>
)

export default Index
